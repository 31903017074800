.page-header {
  padding: 2rem 0rem 1rem 0rem;
  height: 8rem;
  //width: 75%;
  display: flex;
  .page-header-main {
    width: 50%;
    h2 {
      max-width: inherit;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    h3 {
      padding: 0.5rem 0rem 0.5rem 0rem;
    }
  }
  .guide-me {
    width: 50%;
    .cds--btn {
      float: right;
      margin-top: 0.5rem;
    }
  }
  .guide-modal-div {
    .cds--modal {
      justify-content: left;
      .cds--modal-header__heading {
        font-size: larger;
        font-weight: 600;
      }
      .cds--modal-content {
        font-size: 16px;
        .instructions {
          font-size: 15px;
          margin-top: 1rem;
          margin-left: 3rem;
          .cds--list__item {
            font-size: 15px;
            line-height: 2rem;
          }
        }
      }
      .cds--modal-container {
        height: 100vh;
        max-block-size: 100%;
        //margin-left: 51%;
      }
    }
  }
}
