// .cds--header {
//     background-color: black;
//     .cds--header__name {
//         color: white;
//     }
//     a.cds--header__name:hover {
//       color: white;
//     }
//     .cds--popover:hover {
//       color: white;
//     }
//     .cds--header__global {
//       .cds--header__action {
//         svg {
//           fill: white
//         }
//       }
//     }
// }
.cds--side-nav__items {
  border-right: 1px solid var(--cds-border-subtle);
}
