.product-related-desc-tile{
    margin-bottom: 1rem;
    .product-rel-desc {
        text-align: justify;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 7;
        display: -webkit-inline-box;
        -webkit-box-orient: vertical;
    }
}
