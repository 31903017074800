.product-tiles {
  .cds--tile {
    margin-bottom: 1rem;
    .product-tile:hover .arrow-icon {
      display: inline; /* Show the arrow icon when hovering */
    }
    .product-tile {
      cursor: pointer;
      display: block;
      margin-bottom: 0.25rem;
      .product-image {
        text-align: center;
      }
      h4 {
        font-size: 1.05rem;
        font-weight: 600;
        padding: 0.5rem 0rem;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .product-details {
        display: flex;
        width: 100%;
        .product-brand {
          width: 50%;
        }
        .product-rating {
          display: flex;
          justify-content: flex-end;
          float: right;
          width: 50%;
          div {
            display: flex;
            font-size: 0.85rem;
            svg {
              margin-right: 0.25rem;
            }
          }
        }
      }
      .product-desc {
        margin: 1rem 0rem;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Limit the description to 3 lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
      .product-price {
        font-weight: 600;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
        div {
          width: 75%;
          text-align: right;
          .cds--tag {
            text-align: center;
            width: fit-content;
            font-size: 1rem;
            font-weight: 600;
            // padding: 0.5rem;
          }
        }
      }
    }
  }
}