.desc-tabs {
  .cds--tab--list {
    inline-size: 50%;
  }
}

.desc-tile-1 {
  margin-bottom: 1rem;
}

.product-desc-tile {
  h3 {
    margin-bottom: 0.25rem;
  }

  .product-price-rating {
    display: flex;
    margin: 1rem 0rem;

    .product-price {
      width: 50%;
    }

    .product-rating {
      margin-bottom: 1rem;
      display: flex;
      justify-content: flex-end;
      float: right;
      width: 50%;

      div {
        display: flex;
        font-size: 0.85rem;

        svg {
          margin-right: 0.25rem;
        }
      }
    }
  }

  .services {
    display: flex;
    width: 100%;
    justify-content: center;

    svg.my-custom-class {
      fill: rebeccapurple;
      width: 45px;
    }

    .cds--tile {
      padding: 0.65rem;
      min-inline-size: 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      svg.delivery-icon {
        fill: green;
      }

      svg.currency-icon {
        fill: green;
      }

      svg.delivery-parcel-icon {
        fill: green;
      }

      svg.manage-protection-icon {
        fill: green;
      }

      svg.trophy-icon {
        fill: green;
      }

      h5 {
        margin-top: 0.5rem;
        font-size: 0.65rem;
      }
    }
  }

  .product-description {
    div {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      margin-top: 0.5rem;
      text-align: justify;
    }
  }
}

.product-specs {
  h5 {
    margin-bottom: 1rem;
  }

  .incident-details-field {
    margin-bottom: 1rem;
  }
}

.product-delivery {
  margin-bottom: 1rem;

  h4 {
    font-weight: 600;
  }

  .free-del {
    color: green;
  }

  .del-date {
    color: rebeccapurple;
  }

  .del-to {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
}

.add-product {
  .free-del {
    color: green;
    font-weight: 600;
  }

  .cds--tag {
    width: 100%;
  }

  .del-date {
    margin: 1rem 0rem;
  }

}