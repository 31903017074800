.product-reviews-desc-tile {
  margin-bottom: 1rem;
}
.reviews-tile {
  margin: 0rem;
  padding: 0rem;

  h5 {
    margin-bottom: 0.5rem;
  }

  .pros-list,
  .cons-list {
    display: flex;

    div {
      display: inline-flex;
      font-size: 15px;
    }

    svg.pros {
      fill: green;
      margin-right: 0.25rem;
      margin-top: 0.2rem;
    }

    svg.cons {
      fill: orangered;
      margin-right: 0.25rem;
      margin-top: 0.2rem;
    }
  }
}

.product-reviews-list {
  display: flex;
  flex-wrap: wrap;

  h5 {
    margin-bottom: 0.5rem;
  }

  .review-tile {
    margin-bottom: 1rem;
    flex: 1 1 calc(50% - 20px);
    /* Set the tile width; adjust as needed */
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 6rem;
    max-height: 6rem;
    text-overflow: ellipsis;
    overflow: scroll;
    /* Ensures the tiles take up full height */
    div {
      padding: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #f9f9f9;
    }
    .user-details {
      display: flex;
      width: 100%;
      strong {
        margin-bottom: 0.5rem;
        width: 80%;
      }
      .rating {
        width: 20%;
        text-align: right;
        svg {
          fill: #24a148;
        }
      }
    }
  }
}

.product-reviews-highlights {
  margin-bottom: 1rem;
  .product-reviews-heading {
    margin-bottom: 1rem;
  }
  .product-top-features,
  .product-top-issues {
    margin-top: 1rem;
    .product-top-reviews {
      padding: 0.5rem 0 0 0;
      margin-right: -1rem;
      div {
        text-overflow: ellipsis;
        overflow: scroll;
        height: 100%;
        min-height: 6rem;
        max-height: 6rem;
        display: flex;
        flex-direction: column;
      }
      .user-details {
        display: flex;
        width: 100%;
        strong {
          margin-bottom: 0.5rem;
          width: 80%;
        }
        .rating {
          width: 20%;
          text-align: right;
          svg {
            fill: #24a148;
          }
        }
      }
    }
  }

  .review-progress-bar {
    width: 80%;
    margin-bottom: 1rem;
    .cds--progress-bar {
      display: flex;
      .cds--progress-bar__label {
        margin-right: 0.5rem;
      }
      .cds--progress-bar__track {
        margin-top: 0.35rem;
      }
    }
  }
  .cds--toast-notification {
    inline-size: auto;
    svg {
      display: none;
    }
    .cds--toast-notification__details {
      width: 100%;
      .cds--toast-notification__subtitle {
        font-weight: 300;
        font-size: 54px;
        line-height: 64px;
        letter-spacing: 0px;
        text-align: center;
      }
    }
  }
}

// .product-reviews {
//   margin-bottom: 1rem;
//   .cds--tile:nth-child(1) {
//     margin-left: -1rem;
//   }
//   .cds--tile {
//     //padding: 1rem 0 0 0;
//     margin-right: -1rem;
//     .tile {
//       text-overflow: ellipsis;
//       overflow: scroll;
//       height: 100%;
//       min-height: 6rem;
//       max-height: 6rem;
//     }
//   }
// }
